<template>
  <div>
    <v-col cols="12" md="5" class="text-h5">
      <span>Статус: </span>
      <span :class="ticketStatus.color + '--text'">
        {{ ticketStatus.text }}
      </span>
    </v-col>
    <v-row v-if="haveSvc">
      <v-col cols="12" md="4" v-for="(svc, i) in order.Pnr.Svcs.Svc" :key="i">
        <svc :svc="svc"></svc>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Svc from "./Svc.vue";
export default {
  components: { Svc },
  props: {
    order: {
      type: Object,
      require: true,
    },
  },
  computed: {
    haveSvc() {
      return this.order.Pnr?.Svcs?.Svc?.length > 0;
    },
    ticketStatus() {
      switch (this.order.Pnr.CommonStatus) {
        case "ticket":
          return { text: `Билеты выпущены`, color: "green" }; // Напечатаны билеты
        case "cancelled":
          return { text: `Бронь отменена`, color: "red" }; // Заказ не выкуплен, таймлимит истек
        case "mso":
          return { text: `Напечатаны квитанции MCO`, color: "green" };
        case "ticket(s) returned":
          return { text: `Билеты аннулированы`, color: "red" }; // Билеты сданы
        case "mco returned":
          return { text: `Квитанции сданы`, color: "red" };
        case "being_paid_for":
          return {
            text: `Заказ ожидает подтверждения оплаты`,
            color: "orange",
          };
        case "not sold":
          return {
            text: `Билеты забронированы`, //
            color: "orange",
          };
      }
    },
  },
};
</script>

<style scoped></style>
