<template>
    <div>
        <v-card>
            <div>
                <v-card-title v-if="!isRefunding" class="headline accent">
                    Информация по возврату
                </v-card-title>
                <v-card-title v-else>
                    <loading-text :text="textToShow" class="text-h5"></loading-text>
                    <v-progress-linear indeterminate color="yellow darken-2"></v-progress-linear>
                </v-card-title>

                <v-card-text>
                    <v-container v-if="refundable">
                        <v-row>
                            <v-expansion-panels v-model="panel" multiple>
                                <refund-info-list :value="toRefund" title="К возврату"></refund-info-list>
                                <refund-info-list v-if="penalties && penalties.length > 0" :value="penalties"
                                    title="Штрафы"></refund-info-list>
                            </v-expansion-panels>
                        </v-row>
                        <v-row justify="end" class="mt-10">
                            <div class="text-h5">Итого к возврату: <span class="green--text">{{ amountSum }} руб</span>
                            </div>
                            <v-spacer></v-spacer>
                            <v-btn text large color="orange" :disabled="isRefunding" @click="cancel()">
                                Отмена
                            </v-btn>
                            <v-btn dark elevation="5" :disabled="isRefunding" :loading="isRefunding" color="orange" large
                                @click="onRefund">
                                Вернуть
                            </v-btn>
                        </v-row>
                    </v-container>
                    <v-container v-else>
                        <v-row class="my-5">
                            <v-col cols="12" class="text-center orange--text text-h2 mb-10">
                                Возврат недоступен
                            </v-col>
                            <v-col v-if="errorReason" cols="12" class="text-center text-subtitle-1">
                                Reason: {{ errorReason }}
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row justify="end" class="mt-3">
                            <v-btn large color="orange" @click="cancel()">
                                Ок
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-card-text>
            </div>
        </v-card>
    </div>
</template>
  
<script>
import OverlayLoader from "@/components/UI/OverlayLoader.vue";
import LoadingText from "@/components/UI/LoadingText.vue";
import RefundInfoList from "@/components/SoldedTicketsPage/RefundInfoList.vue";
export default {
    name: "RefundModal",
    components: { OverlayLoader, LoadingText, RefundInfoList },
    props: {
        ticket: {
            type: String,
            require: true,
        },
        toRefund: {
            type: Array,
            require: true,
        },
        penalties: {
            type: Array,
            default: () => [],
        },
        refundable: {
            type: Boolean,
            default: false,
        },
        errorReason: {
            type: String,
            default: "Неизвестно",
        },
    },
    data() {
        return {
            isRefunding: false,
            textToShow: "Идет возврат билета",
            panel: [0],
            featuresOpen: false,
            closeOnContentClick: true,
        };
    },
    methods: {
        cancel() {
            this.$emit("cancel", false);
        },
        toggleFeatures() {
            this.featuresOpen = !this.featuresOpen;
        },
        async onRefund() {
            this.isRefunding = true;
            try {
                let resp = await this.$axios.post(this.$enums.Endpoints.Order.Refund + `?id=${this.ticket}`);
                this.$emit('onRefund');
            } catch (e) {
                throw e;
            } finally {
                this.isRefunding = false;
            }
        }
    },
    computed: {
        amountSum() {
            let sum = 0;
            this.toRefund.forEach((s) => {
                sum += s.total;
            });
            return sum;
        },
    }
};
</script>

<style>
.panel {
    width: 33%;
}

.v-expansion-panel-content__wrap {
    padding: 0 10px 16px;
}

.panels {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
</style>