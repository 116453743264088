<template>
  <v-row v-if="sum" class="text-h5 justify-end">
    <v-col cols="12">
      Итого к возврату: {{ sum }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "RefundSumPrice",
  props: {
    sum: {
      type: Number,
      default: 0
    }
  },
};
</script>

<style scoped>

</style>