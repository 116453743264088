<template>
  <v-row align="center">
    <v-col cols="12" md="5" class="text-center text-h5">
      <segment-station-info
        :name="data.departure.name"
        :date="new Date(data.departure.date)"
      ></segment-station-info>
    </v-col>
    <v-col cols="12" md="2" class="text-center text-h5">
      <div>
        Рейс: {{ data.flightNumber }}
      </div>
      <span id="sity-arrow" class="text-h2 green--text darken-4--text">
        {{ $vuetify.breakpoint.xsOnly ? "☟" : "➫" }}
      </span>
    </v-col>
    <v-col cols="12" md="5" class="text-center text-h5">
      <segment-station-info
        :name="data.arrival.name"
        :date="new Date(data.arrival.date)"
      ></segment-station-info>
    </v-col>
  </v-row>
</template>

<script>
import SegmentStationInfo from "@/components/SoldedTicketsPage/SegmentStationInfo.vue";

export default {
  components: { SegmentStationInfo },
  name: "Segment",
  props: {
    data: {
      type: Object,
      require: true,
    },
  },
};
</script>

<style></style>
