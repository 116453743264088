<template>
  <v-card class="pt-1">
    <!-- Отображение первого сегмента -->
    <v-card-text>
      <TrainSegment :train-ticket-segment="trainTicketSegment"></TrainSegment>
    </v-card-text>
    <div>
      <TrainTicketsModal
          :trainTickets="trainTicketSegment.trainTickets"
          :isVisible="dialogVisible"
          @update:isVisible="updateDialogVisibility"
      />
    </div>
    <!--    <v-expand-transition>
          <div v-show="extended">
            <TrainSegment
                v-for="(item, i) in secondaryArray"
                :key="i"
                :train-ticket-segment="item"
            ></TrainSegment>
          </div>
        </v-expand-transition>
        <v-card-actions v-if="trainTicketSegment.length > 1" class="justify-center">
          <v-btn
              justify-content="center"
              color="orange"
              elevation="0"
              @click="extended = !extended"
          >
            {{ extended ? "Свернуть" : "Развернуть" }}
          </v-btn>
        </v-card-actions>-->
  </v-card>

</template>

<script>
import TrainSegment from '@/components/SoldedTicketsPage/TrainSegment.vue';
import Segment from "@/components/SoldedTicketsPage/Segment.vue";
import TrainTicketsModal from "@/components/SoldedTicketsPage/TrainTicketsModal.vue";

export default {
  components: {
    TrainTicketsModal,
    Segment,
    TrainSegment
  },
  props: {

    trainTicketSegment: {
      type: Object,
      required: true,
      default: () => [],
    },
    segment: '',
  },
  data() {
    return {
      extended: false,
      secondaryArray: [],
    };
  },
  methods: {
    toggleExtended() {
      this.isExtended = !this.isExtended;
    }
  },
  mounted() {

  },
  computed: {},
};
</script>

