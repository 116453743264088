<template>
  <v-expansion-panel class="panels">
    <v-expansion-panel-header><h2>{{ title }}</h2></v-expansion-panel-header>

    <v-expansion-panel-content>
        <v-expansion-panels multiple>
          <v-col :cols="12 / (value.length > 2 ? 3 : value.length == 0 ? 1 : value.length)" v-for="(item, i) in value" :key="i">
            <refund-info-item :value="item"></refund-info-item>
          </v-col>
        </v-expansion-panels>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import RefundInfoItem from '@/components/SoldedTicketsPage/RefundInfoItem.vue';

export default {
    name: "RefundInfoList",
    props: {
        value: {
            type: Array,
            require: true,
        },
        title: {
            type: String,
            require: true,
        }
    },
    components: { RefundInfoItem }
};
</script>